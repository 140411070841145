:root {
  --accent-100: #efecff;
  --accent-200: #b0e9ff;
  --accent-300: #b3a7f5;
  --accent-400: #8876ee;
  --accent-500: #644de8;
  --accent-600: #4d33e4;
  --accent-700: #4126e4;
  --accent-800: #3319cb;
  --accent-900: #2c15b6;
  --green-100: #dcfae6;
  --green-200: #abefc6;
  --green-300: #75e0a7;
  --green-400: #47cd89;
  --green-500: #17b26a;
  --green-600: #079455;
  --green-700: #067647;
  --green-800: #085d3a;
  --green-900: #074d31;
  --neutral-100: #f2f4f7;
  --neutral-200: #eaecf0;
  --neutral-300: #d0d5dd;
  --neutral-400: #98a2b3;
  --neutral-500: #667085;
  --neutral-600: #475467;
  --neutral-700: #344054;
  --neutral-800: #182230;
  --neutral-900: #101828;
  --primary: #000f0f;
  --red-100: #fee4e2;
  --red-200: #fecdca;
  --red-300: #fda29b;
  --red-400: #f97066;
  --red-500: #f04438;
  --red-600: #d92d20;
  --red-700: #b42318;
  --red-800: #912018;
  --red-900: #7a271a;
  --secondary: #667085;
  --transparent: transparent;
  --white: #ffffff;

  /* accordion */

  --background-accordion-item: transparent;
  --border-color-accordion-item: var(--neutral-200);
  --border-radius-accordion-item: 0;
  --border-size-accordion-item: 1px;
  --color-accordion-body: var(--neutral-700);
  --color-accordion-title: var(--neutral-700);
  --color-accordion-title-icon: var(--primary);
  --line-height-large-breakpoint-accordion-body: 140%;
  --line-height-medium-breakpoint-accordion-body: 140%;
  --line-height-small-breakpoint-accordion-body: 140%;
  --size-large-breakpoint-accordion-body: 1.125rem;
  --size-large-breakpoint-accordion-icon: 1.25rem;
  --size-large-breakpoint-accordion-title: 1.125rem;
  --size-medium-breakpoint-accordion-body: 1.125rem;
  --size-medium-breakpoint-accordion-icon: 1.25rem;
  --size-medium-breakpoint-accordion-title: 1.125rem;
  --size-small-breakpoint-accordion-body: 1.125rem;
  --size-small-breakpoint-accordion-icon: 1.25rem;
  --size-small-breakpoint-accordion-title: 1.125rem;
  --space-x-large-breakpoint-accordion-body-inner: 0;
  --space-x-large-breakpoint-accordion-header: 0;
  --space-x-medium-breakpoint-accordion-body-inner: 0;
  --space-x-medium-breakpoint-accordion-header: 0;
  --space-x-small-breakpoint-accordion-body-inner: 0;
  --space-x-small-breakpoint-accordion-header: 0;
  --space-y-block-large-breakpoint-accordion-body: 1.5625rem;
  --space-y-block-medium-breakpoint-accordion-body: 1.5625rem;
  --space-y-block-small-breakpoint-accordion-body: 1.5625rem;
  --space-y-large-breakpoint-accordion-header: 1.25rem;
  --space-y-medium-breakpoint-accordion-header: 1.25rem;
  --space-y-small-breakpoint-accordion-header: 1.25rem;
  --weight-accordion-body: var(--weight-regular);
  --weight-accordion-title: var(--weight-bold);

  /* button */

  --border-radius-button: 8px;
  --line-height-button-lg: 110%;
  --line-height-button-md: 110%;
  --line-height-button-sm: 110%;
  --size-large-breakpoint-button-lg: 1.375rem;
  --size-large-breakpoint-button-md: 1.125rem;
  --size-large-breakpoint-button-sm: 1rem;
  --size-medium-breakpoint-button-lg: 1.375rem;
  --size-medium-breakpoint-button-md: 1.125rem;
  --size-medium-breakpoint-button-sm: 1rem;
  --size-small-breakpoint-button-lg: 1.125rem;
  --size-small-breakpoint-button-md: 1rem;
  --size-small-breakpoint-button-sm: 1rem;
  --space-x-large-breakpoint-button-lg: 2.1875rem;
  --space-x-large-breakpoint-button-md: 1.875rem;
  --space-x-large-breakpoint-button-sm: 1rem;
  --space-x-medium-breakpoint-button-lg: 2.1875rem;
  --space-x-medium-breakpoint-button-md: 1.875rem;
  --space-x-medium-breakpoint-button-sm: 1rem;
  --space-x-small-breakpoint-button-lg: 1.875rem;
  --space-x-small-breakpoint-button-md: 1.5625rem;
  --space-x-small-breakpoint-button-sm: 1rem;
  --space-y-large-breakpoint-button-lg: 0.9375rem;
  --space-y-large-breakpoint-button-md: 0.9375rem;
  --space-y-large-breakpoint-button-sm: 0.625rem;
  --space-y-medium-breakpoint-button-lg: 1.125rem;
  --space-y-medium-breakpoint-button-md: 0.9375rem;
  --space-y-medium-breakpoint-button-sm: 0.625rem;
  --space-y-small-breakpoint-button-lg: 1.125rem;
  --space-y-small-breakpoint-button-md: 0.625rem;
  --space-y-small-breakpoint-button-sm: 0.625rem;
  --weight-button: var(--weight-bold);

  /* button-primary */

  --background-button-primary: var(--primary);
  --background-button-primary-disabled: var(--neutral-300);
  --background-button-primary-focus: var(--accent-700);
  --background-button-primary-hover: var(--neutral-800);
  --border-color-button-primary: var(--transparent);
  --border-color-button-primary-focus: var(--accent-700);
  --border-color-button-primary-hover: var(--transparent);
  --border-width-button-primary: 1px;
  --color-button-primary: var(--white);
  --color-button-primary-hover: var(--white);

  /* button-secondary */

  --background-button-secondary: var(--white);
  --background-button-secondary-disabled: var(--neutral-300);
  --background-button-secondary-focus: var(--neutral-100);
  --background-button-secondary-hover: var(--neutral-100);
  --border-color-button-secondary: var(--neutral-300);
  --border-color-button-secondary-focus: var(--neutral-300);
  --border-color-button-secondary-hover: var(--neutral-300);
  --border-width-button-secondary: 1px;
  --color-button-secondary: var(--neutral-500);
  --color-button-secondary-hover: var(--neutral-500);

  /* cards */

  --background-card: var(--white);
  --background-card-eyebrow: transparent;
  --background-card-icon: #e0ffff;
  --border-color-card-eyebrow: transparent;
  --border-radius-card: 0;
  --border-radius-card-eyebrow: 0;
  --border-radius-card-icon: 30px;
  --border-width-card-eyebrow: 0;
  --color-card-eyebrow: var(--neutral-700);
  --color-card-headline: var(--neutral-900);
  --color-card-icon: var(--primary);
  --color-card-text: var(--neutral-700);
  --gap-card-grid: 60px;
  --line-height-large-breakpoint-card-eyebrow: 140%;
  --line-height-large-breakpoint-card-headline: 140%;
  --line-height-large-breakpoint-card-text: 140%;
  --line-height-medium-breakpoint-card-eyebrow: 140%;
  --line-height-medium-breakpoint-card-headline: 140%;
  --line-height-medium-breakpoint-card-text: 140%;
  --line-height-small-breakpoint-card-eyebrow: 140%;
  --line-height-small-breakpoint-card-headline: 140%;
  --line-height-small-breakpoint-card-text: 140%;
  --size-card-icon: 1.25rem;
  --size-large-breakpoint-card-eyebrow: 0.875rem;
  --size-large-breakpoint-card-headline: 1.875rem;
  --size-large-breakpoint-card-text: 1rem;
  --size-medium-breakpoint-card-eyebrow: 0.875rem;
  --size-medium-breakpoint-card-headline: 1.25rem;
  --size-medium-breakpoint-card-text: 0.875rem;
  --size-small-breakpoint-card-eyebrow: 0.875rem;
  --size-small-breakpoint-card-headline: 1.25rem;
  --size-small-breakpoint-card-text: 0.875rem;
  --space-block-y-large-breakpoint-card-eyebrow: 0.3125rem;
  --space-block-y-large-breakpoint-card-grid-item: 0;
  --space-block-y-large-breakpoint-card-headline: 0.5rem;
  --space-block-y-large-breakpoint-card-icon: 0.9375rem;
  --space-block-y-medium-breakpoint-card-eyebrow: 0.3125rem;
  --space-block-y-medium-breakpoint-card-grid-item: 2.5rem;
  --space-block-y-medium-breakpoint-card-headline: 0.5rem;
  --space-block-y-medium-breakpoint-card-icon: 0.9375rem;
  --space-block-y-small-breakpoint-card-eyebrow: 0.3125rem;
  --space-block-y-small-breakpoint-card-grid-item: 2.5rem;
  --space-block-y-small-breakpoint-card-headline: 0.5rem;
  --space-block-y-small-breakpoint-card-icon: 0.9375rem;
  --space-x-large-breakpoint-card: 0;
  --space-x-large-breakpoint-card-eyebrow: 0;
  --space-x-large-breakpoint-card-icon: 0.625rem;
  --space-x-medium-breakpoint-card: 0;
  --space-x-medium-breakpoint-card-eyebrow: 0;
  --space-x-medium-breakpoint-card-icon: 0.625rem;
  --space-x-small-breakpoint-card: 0;
  --space-x-small-breakpoint-card-eyebrow: 0;
  --space-x-small-breakpoint-card-icon: 0.625rem;
  --space-y-large-breakpoint-card: 0;
  --space-y-large-breakpoint-card-eyebrow: 0;
  --space-y-large-breakpoint-card-icon: 0.625rem;
  --space-y-medium-breakpoint-card: 0;
  --space-y-medium-breakpoint-card-eyebrow: 0;
  --space-y-medium-breakpoint-card-icon: 0.625rem;
  --space-y-small-breakpoint-card: 0;
  --space-y-small-breakpoint-card-eyebrow: 0;
  --space-y-small-breakpoint-card-icon: 0.625rem;
  --weight-card-eyebrow: var(--weight-bold);
  --weight-card-headline: var(--weight-bold);
  --weight-card-text: var(--weight-regular);

  /* container */

  --max-width-container-extralarge: 1320px;
  --max-width-container-extrasmall: 540px;
  --max-width-container-large: 1170px;
  --max-width-container-medium: 960px;
  --max-width-container-small: 720px;
  --space-large-breakpoint-container: auto;
  --space-medium-breakpoint-container: 1.5rem;
  --space-small-breakpoint-container: 0.875rem;

  /* form */

  --border-color-form: var(--neutral-300);
  --border-color-form-error: var(--red-600);
  --border-color-form-focus: var(--accent-500);
  --border-radius-form: 8px;
  --border-width-form: 1px;
  --color-form-label: var(--neutral-900);
  --color-form-outline: var(--accent-200);
  --color-form-outline-error: var(--red-200);
  --color-message-error: var(--red-600);
  --column-gap-form-grid: 30px;
  --size-form-outline: 0.125rem;
  --size-large-breakpoint-form-element-label: 0.875rem;
  --size-large-breakpoint-message-error-icon: 0.625rem;
  --size-medium-breakpoint-form-element-label: 0.875rem;
  --size-medium-breakpoint-message-error-icon: 0.625rem;
  --size-small-breakpoint-form-element-label: 0.875rem;
  --size-small-breakpoint-message-error-icon: 0.625rem;
  --space-y-block-large-breakpoint-form-element: 2.1875rem;
  --space-y-block-large-breakpoint-form-element-label: 0.5rem;
  --space-y-block-medium-breakpoint-form-element: 2.1875rem;
  --space-y-block-medium-breakpoint-form-element-label: 0.5rem;
  --space-y-block-small-breakpoint-form-element: 2.1875rem;
  --space-y-block-small-breakpoint-form-element-label: 0.5rem;
  --weight-form-label: var(--weight-medium);

  /* form-checkbox */

  --color-checkbox-icon: var(--neutral-400);
  --color-checkbox-icon-checked: var(--primary);
  --color-checkbox-label: var(--neutral-700);
  --size-large-breakpoint-checkbox-icon: 1.125rem;
  --size-large-breakpoint-checkbox-label: 1rem;
  --size-medium-breakpoint-checkbox-icon: 1.125rem;
  --size-medium-breakpoint-checkbox-label: 1rem;
  --size-small-breakpoint-checkbox-icon: 1.125rem;
  --size-small-breakpoint-checkbox-label: 1rem;
  --space-x-large-breakpoint-message-items: 0.625rem;
  --space-x-medium-breakpoint-message-items: 0.625rem;
  --space-x-small-breakpoint-message-items: 0.625rem;
  --space-y-block-large-breakpoint-checkbox: 0.3125rem;
  --space-y-block-medium-breakpoint-checkbox: 0.3125rem;
  --space-y-block-small-breakpoint-checkbox: 0.3125rem;
  --space-y-large-breakpoint-message-items: 0.625rem;
  --space-y-medium-breakpoint-message-items: 0.625rem;
  --space-y-small-breakpoint-message-items: 0.625rem;

  /* form-datepicker */

  --background-datepicker-calendar-day-active: var(--primary);
  --background-datepicker-calendar-day-hover: var(--primary);
  --color-datepicker-calendar-day: var(--neutral-700);
  --color-datepicker-calendar-day-active: var(--white);
  --color-datepicker-calendar-day-hover: var(--white);
  --color-datepicker-calendar-header-days: var(--neutral-500);
  --color-datepicker-month-dropdown: var(--neutral-800);

  /* form-input */

  --background-form: var(--white);
  --color-form: var(--neutral-500);
  --color-form-element-icon: var(--primary);
  --color-form-error: var(--red-600);
  --color-form-placeholder: var(--neutral-500);
  --min-height-large-breakpoint-textarea: 250px;
  --min-height-medium-breakpoint-textarea: 250px;
  --min-height-small-breakpoint-textarea: 250px;
  --size-large-breakpoint-form: 1rem;
  --size-large-breakpoint-form-element-icon: 1.125rem;
  --size-medium-breakpoint-form: 1rem;
  --size-medium-breakpoint-form-element-icon: 1.125rem;
  --size-small-breakpoint-form: 1rem;
  --size-small-breakpoint-form-element-icon: 1.125rem;
  --space-x-large-breakpoint-form: 0.875rem;
  --space-x-medium-breakpoint-form: 0.875rem;
  --space-x-small-breakpoint-form: 0.875rem;
  --space-y-large-breakpoint-form: 0.875rem;
  --space-y-medium-breakpoint-form: 0.875rem;
  --space-y-small-breakpoint-form: 0.875rem;

  /* form-radio */

  --color-radio-icon: var(--neutral-400);
  --color-radio-icon-active: var(--primary);
  --color-radio-label: var(--neutral-700);
  --size-large-breakpoint-radio-icon: 1.125rem;
  --size-large-breakpoint-radio-label: 1rem;
  --size-medium-breakpoint-radio-icon: 1.125rem;
  --size-medium-breakpoint-radio-label: 1rem;
  --size-small-breakpoint-radio-icon: 1.125rem;
  --size-small-breakpoint-radio-label: 1rem;
  --space-y-block-large-breakpoint-radio: 0.375rem;
  --space-y-block-medium-breakpoint-radio: 0.375rem;
  --space-y-block-small-breakpoint-radio: 0.375rem;

  /* form-select */

  --background-select-option-hover: var(--accent-50);
  --background-select-options: var(--white);
  --background-select-trigger: var(--white);
  --border-color-select-options: var(--neutral-300);
  --border-color-select-trigger: var(--neutral-300);
  --border-color-select-trigger-visible: var(--accent-500);
  --border-width-select-options: 1px;
  --border-width-select-trigger: 1px;
  --color-select-option: var(--neutral-600);
  --color-select-trigger: var(--neutral-600);
  --space-x-large-breakpoint-select: 0.9375rem;
  --space-x-large-breakpoint-select-option: 0.9375rem;
  --space-x-medium-breakpoint-select: 0.9375rem;
  --space-x-medium-breakpoint-select-option: 0.9375rem;
  --space-x-small-breakpoint-select: 0.9375rem;
  --space-x-small-breakpoint-select-option: 0.9375rem;
  --space-y-large-breakpoint-select: 0.875rem;
  --space-y-large-breakpoint-select-option: 0.875rem;
  --space-y-medium-breakpoint-select: 0.875rem;
  --space-y-medium-breakpoint-select-option: 0.875rem;
  --space-y-small-breakpoint-select: 0.875rem;
  --space-y-small-breakpoint-select-option: 0.875rem;

  /* hero */

  --background-hero: var(--white);
  --background-hero-eyebrow: transparent;
  --border-color-hero: var(--transparent);
  --border-color-hero-eyebrow: var(--neutral-300);
  --border-radius-hero: 0;
  --border-radius-hero-eyebrow: 25px;
  --border-width-hero: 0;
  --border-width-hero-eyebrow: 1px;
  --color-hero-eyebrow: var(--neutral-600);
  --color-hero-eyebrow-link: var(--primary);
  --color-hero-headline: var(--neutral-900);
  --color-hero-text: var(--neutral-600);
  --horizontal-alignment-hero: center;
  --line-height-large-breakpoint-hero-eyebrow: 140%;
  --line-height-large-breakpoint-hero-headline: 140%;
  --line-height-large-breakpoint-hero-text: 140%;
  --line-height-medium-breakpoint-hero-eyebrow: 140%;
  --line-height-medium-breakpoint-hero-headline: 140%;
  --line-height-medium-breakpoint-hero-text: 140%;
  --line-height-small-breakpoint-hero-eyebrow: 140%;
  --line-height-small-breakpoint-hero-headline: 140%;
  --line-height-small-breakpoint-hero-text: 140%;
  --min-height-large-breakpoint-hero: 600px;
  --min-height-medium-breakpoint-hero: 600px;
  --min-height-small-breakpoint-hero: 0;
  --size-large-breakpoint-hero-eyebrow: 0.9375rem;
  --size-large-breakpoint-hero-headline: 4.5rem;
  --size-large-breakpoint-hero-text: 1.5rem;
  --size-medium-breakpoint-hero-eyebrow: 0.9375rem;
  --size-medium-breakpoint-hero-headline: 3rem;
  --size-medium-breakpoint-hero-text: 1.25rem;
  --size-small-breakpoint-hero-eyebrow: 0.875rem;
  --size-small-breakpoint-hero-headline: 2rem;
  --size-small-breakpoint-hero-text: 1.125rem;
  --space-x-large-breakpoint-hero: 0;
  --space-x-large-breakpoint-hero-eyebrow: 1.5rem;
  --space-x-medium-breakpoint-hero: 0;
  --space-x-medium-breakpoint-hero-eyebrow: 1.5rem;
  --space-x-small-breakpoint-hero: 0;
  --space-x-small-breakpoint-hero-eyebrow: 1.5rem;
  --space-y-block-large-breakpoint-hero-button: 1.25rem;
  --space-y-block-large-breakpoint-hero-eyebrow: 0.625rem;
  --space-y-block-large-breakpoint-hero-text: 0.625rem;
  --space-y-block-medium-breakpoint-hero-button: 1.25rem;
  --space-y-block-medium-breakpoint-hero-eyebrow: 0.625rem;
  --space-y-block-medium-breakpoint-hero-text: 0.625rem;
  --space-y-block-small-breakpoint-hero-button: 1.25rem;
  --space-y-block-small-breakpoint-hero-eyebrow: 1.25rem;
  --space-y-block-small-breakpoint-hero-text: 0.625rem;
  --space-y-large-breakpoint-hero: 0;
  --space-y-large-breakpoint-hero-eyebrow: 0.3125rem;
  --space-y-medium-breakpoint-hero: 0;
  --space-y-medium-breakpoint-hero-eyebrow: 0.3125rem;
  --space-y-small-breakpoint-hero: 0;
  --space-y-small-breakpoint-hero-eyebrow: 0.3125rem;
  --weight-hero-eyebrow: var(--weight-bold);
  --weight-hero-headline: var(--weight-bold);
  --weight-hero-text: var(--weight-regular);

  /* modal */

  --background-modal-backdrop: var(--accent-100);
  --background-modal-body: var(--white);
  --border-color-modal-body: var(--accent-200);
  --border-radius-modal-body: 8px;
  --border-width-large-breakpoint-modal-body: 1px;
  --border-width-medium-breakpoint-modal-body: 1px;
  --border-width-small-breakpoint-modal-body: 0;
  --color-modal-body: var(--neutral-700);
  --color-modal-close: var(--neutral-500);
  --color-modal-shadow: var(--accent-200);
  --color-modal-title: var(--neutral-900);
  --line-height-large-breakpoint-modal-body: 140%;
  --line-height-medium-breakpoint-modal-body: 140%;
  --line-height-small-breakpoint-modal-body: 140%;
  --max-width-large-breakpoint-modal-body: 450px;
  --max-width-medium-breakpoint-modal-body: 450px;
  --max-width-small-breakpoint-modal-body: 100%;
  --size-large-breakpoint-modal-body: 1rem;
  --size-large-breakpoint-modal-title: 1.5625rem;
  --size-medium-breakpoint-modal-body: 1rem;
  --size-medium-breakpoint-modal-title: 1.5625rem;
  --size-modal-close: 0.8125rem;
  --size-modal-shadow: 0.1875rem;
  --size-small-breakpoint-modal-body: 1rem;
  --size-small-breakpoint-modal-title: 1.5625rem;
  --space-x-large-breakpoint-modal: 1.875rem;
  --space-x-medium-breakpoint-modal: 1.875rem;
  --space-x-small-breakpoint-modal: 0;
  --space-y-block-large-breakpoint-modal-header: 1.875rem;
  --space-y-block-medium-breakpoint-modal-header: 1.875rem;
  --space-y-block-small-breakpoint-modal-header: 1.875rem;
  --space-y-large-breakpoint-modal: 1.875rem;
  --space-y-medium-breakpoint-modal: 1.875rem;
  --space-y-small-breakpoint-modal: 0;
  --weight-modal-body: var(--weight-regular);
  --weight-modal-title: var(--weight-bold);

  /* text */

  --background-text-eyebrow: transparent;
  --border-color-text-eyebrow: transparent;
  --border-radius-text-eyebrow: 0;
  --border-width-text-eyebrow: 0;
  --color-text-eyebrow: var(--neutral-700);
  --color-text-headline: var(--neutral-900);
  --color-text-section: var(--neutral-600);
  --horizontal-alignment-text: left;
  --line-height-large-breakpoint-text-eyebrow: 140%;
  --line-height-large-breakpoint-text-headline: 140%;
  --line-height-large-breakpoint-text-section: 140%;
  --line-height-medium-breakpoint-text-eyebrow: 140%;
  --line-height-medium-breakpoint-text-headline: 140%;
  --line-height-medium-breakpoint-text-section: 140%;
  --line-height-small-breakpoint-text-eyebrow: 140%;
  --line-height-small-breakpoint-text-headline: 140%;
  --line-height-small-breakpoint-text-section: 140%;
  --size-large-breakpoint-text-eyebrow: 0.875rem;
  --size-large-breakpoint-text-headline: 2.375rem;
  --size-large-breakpoint-text-section: 1.125rem;
  --size-medium-breakpoint-text-eyebrow: 0.875rem;
  --size-medium-breakpoint-text-headline: 2.375rem;
  --size-medium-breakpoint-text-section: 1.125rem;
  --size-small-breakpoint-text-eyebrow: 0.875rem;
  --size-small-breakpoint-text-headline: 2rem;
  --size-small-breakpoint-text-section: 1rem;
  --space-x-large-breakpoint-text: 0;
  --space-x-large-breakpoint-text-eyebrow: 0;
  --space-x-medium-breakpoint-text: 0;
  --space-x-medium-breakpoint-text-eyebrow: 0;
  --space-x-small-breakpoint-text: 0;
  --space-x-small-breakpoint-text-eyebrow: 0;
  --space-y-block-large-breakpoint-text-button: 1.5625rem;
  --space-y-block-large-breakpoint-text-eyebrow: 0.3125rem;
  --space-y-block-large-breakpoint-text-headline: 0.625rem;
  --space-y-block-medium-breakpoint-text-button: 1.5625rem;
  --space-y-block-medium-breakpoint-text-eyebrow: 0.3125rem;
  --space-y-block-medium-breakpoint-text-headline: 0.625rem;
  --space-y-block-small-breakpoint-text-button: 1.5625rem;
  --space-y-block-small-breakpoint-text-eyebrow: 0.3125rem;
  --space-y-block-small-breakpoint-text-headline: 0.625rem;
  --space-y-large-breakpoint-text: 0;
  --space-y-large-breakpoint-text-eyebrow: 0.3125rem;
  --space-y-medium-breakpoint-text: 0;
  --space-y-medium-breakpoint-text-eyebrow: 0.3125rem;
  --space-y-small-breakpoint-text: 0;
  --space-y-small-breakpoint-text-eyebrow: 0.3125rem;
  --weight-text-eyebrow: var(--weight-bold);
  --weight-text-headline: var(--weight-bold);
  --weight-text-section: var(--weight-regular);

  /* typography */

  --font-text: Nunito;
  --weight-bold: 700;
  --weight-extrabold: 900;
  --weight-medium: 500;
  --weight-regular: 400;
}
