.form {
  --color-legend: var(--color-form-label);
  --weight-legend: var(--weight-form-label);
  --size-large-breakpoint-legend: var(--size-large-breakpoint-form-element-label);
  --size-medium-breakpoint-legend: var(--size-medium-breakpoint-form-element-label);
  --size-small-breakpoint-legend: var(--size-small-breakpoint-form-element-label);
  --space-y-block-large-breakpoint-legend: var(--space-y-block-large-breakpoint-form-element-label);
  --space-y-block-medium-breakpoint-legend: var(
    --space-y-block-medium-breakpoint-form-element-label
  );
  --space-y-block-small-breakpoint-legend: var(--space-y-block-small-breakpoint-form-element-label);
  --column-gap-form-grid: var(--gap);
  --space-y-block-legend-label-addon: var(--space-y-block-form-label);
  --color-form-element-label: var(--color-form-label);
  --size-large-breakpoint-form-element-label: var(--size-large-breakpoint-form-label);
  --size-medium-breakpoint-form-element-label: var(--size-medium-breakpoint-form-label);
  --size-small-breakpoint-form-element-label: var(--size-small-breakpoint-form-label);
  --weight-form-element-label: var(--weight-form-label);
  --width-large-breakpoint-form-element-icon: 50px;
  --width-medium-breakpoint-form-element-icon: 50px;
  --width-small-breakpoint-form-element-icon: 50px;
  --height-form-element-icon: calc(100% - 2px);
  --space-x-large-breakpoint-form-element-icon: 3.125rem;
  --space-x-medium-breakpoint-form-element-icon: 3.125rem;
  --space-x-small-breakpoint-form-element-icon: 3.125rem;
  --background-input: var(--background-form);
  --border-width-input: var(--border-width-form);
  --border-color-input: var(--border-color-form);
  --border-radius-input: var(--border-radius-form);
  --color-input: var(--color-form);
  --size-large-breakpoint-input: var(--size-large-breakpoint-form);
  --size-medium-breakpoint-input: var(--size-medium-breakpoint-form);
  --size-small-breakpoint-input: var(--size-small-breakpoint-form);
  --space-x-large-breakpoint-input: var(--space-x-large-breakpoint-form);
  --space-x-medium-breakpoint-input: var(--space-x-medium-breakpoint-form);
  --space-x-small-breakpoint-input: var(--space-x-small-breakpoint-form);
  --space-y-large-breakpoint-input: var(--space-y-large-breakpoint-form);
  --space-y-medium-breakpoint-input: var(--space-y-medium-breakpoint-form);
  --space-y-small-breakpoint-input: var(--space-y-small-breakpoint-form);
  --color-input-placeholder: var(--color-form-placeholder);
  --border-color-input-focus: var(--border-color-form-focus);
  --color-input-error: var(--color-form-error);
  --border-color-input-error: var(--border-color-form-error);
  --background-textarea: var(--background-form);
  --border-width-textarea: var(--border-width-form);
  --border-color-textarea: var(--border-color-form);
  --border-radius-textarea: var(--border-radius-form);
  --color-textarea: var(--color-form);
  --space-x-large-breakpoint-textarea: var(--space-x-large-breakpoint-form);
  --space-x-medium-breakpoint-textarea: var(--space-x-medium-breakpoint-form);
  --space-x-small-breakpoint-textarea: var(--space-x-small-breakpoint-form);
  --space-y-large-breakpoint-textarea: var(--space-y-large-breakpoint-form);
  --space-y-medium-breakpoint-textarea: var(--space-y-medium-breakpoint-form);
  --space-y-small-breakpoint-textarea: var(--space-y-small-breakpoint-form);
  --color-textarea-placeholder: var(--color-form-placeholder);
  --border-color-textarea-focus: var(--border-color-form-focus);
  --border-color-textarea-error: var(--border-color-form-error);
  --size-large-breakpoint-checkbox-label: var(--size-large-breakpoint-form);
  --size-medium-breakpoint-checkbox-label: var(--size-medium-breakpoint-form);
  --size-small-breakpoint-checkbox-label: var(--size-small-breakpoint-form);
  --size-large-breakpoint-radio-label: var(--size-large-breakpoint-form);
  --size-medium-breakpoint-radio-label: var(--size-medium-breakpoint-form);
  --size-small-breakpoint-radio-label: var(--size-small-breakpoint-form);
  --border-radius-select-trigger: var(--border-radius-form);
  --size-large-breakpoint-select-trigger: var(--size-large-breakpoint-form);
  --size-medium-breakpoint-select-trigger: var(--size-medium-breakpoint-form);
  --size-small-breakpoint-select-trigger: var(--size-small-breakpoint-form);
  --border-radius-select-trigger-visible: var(--border-radius-form);
  --color-select-trigger-icon: var(--neutral-500);
  --size-select-trigger-icon: 15px;
  --background-select-options: var(--background-form);
  --border-radius-select-options: var(--border-radius-form);
  --space-x-large-breakpoint-select-options: 0;
  --space-x-medium-breakpoint-select-options: 0;
  --space-x-small-breakpoint-select-options: 0;
  --space-y-large-breakpoint-select-options: 0.3125rem;
  --space-y-medium-breakpoint-select-options: 0.3125rem;
  --space-y-small-breakpoint-select-options: 0.3125rem;
  --size-message: 0.75rem;
  --space-y-block-bottom-message: 0.5rem;
  --border-width-message-error-items: var(--border-width-form);
  --border-color-message-error-items: var(--border-color-form-error);
  --border-radius-message-error-items: var(--border-radius-form);
  --background-datepicker: #fff;
  --border-width-datepicker: 1px;
  --border-color-datepicker: var(--border-color-input);
  --border-radius-datepicker: var(--border-radius-form);
  --width-large-breakpoint-datepicker: 360px;
  --width-medium-breakpoint-datepicker: 360px;
  --width-small-breakpoint-datepicker: 300px;
  --color-datepicker-prev-next: var(--neutral-500);
  --size-datepicker-prev-next: 12px;
  --background-hover-datepicker-prev-next: 1px solid var(--neutral-200);
  --border-color-datepicker-prev-next: var(--neutral-300);
  --size-datepicker-month: 12px;
  --border-bottom-datepicker-calendar-header: 1px solid var(--neutral-300);
  --space-y-block-datepicker-calendar-header: 0.625rem;
  --space-y-datepicker-calendar-header: 0.9375rem;
  --weight-datepicker-calendar-header-days: 700;
  --size-datepicker-calendar-header-days: 0.75rem;
  --size-datepicker-calendar-day: 0.75rem;
  --weight-datepicker-calendar-day: 700;
  --space-y-datepicker-calendar-day: 0.9375rem 0;
  --border-datepicker-calendar-day: 1px solid var(--neutral-100);
  --space-x-multiselect-count: 0.4375rem;
  --space-y-multiselect-count: 0.1875rem;
  --background-multiselect: var(--neutral-600);
  --border-radius-multiselect: 4px;
  --color-multiselect: #fff;
  --size-multiselect: 0.75rem;
  --space-x-block-multiselect: 0.5rem;
  --space-x-large-breakpoint-multiselect: 1.25rem;
  --space-x-medium-breakpoint-multiselect: 1.25rem;
  --space-x-small-breakpoint-multiselect: 1.25rem;
  --space-y-large-breakpoint-multiselect: 0.625rem;
  --space-y-medium-breakpoint-multiselect: 0.625rem;
  --space-y-small-breakpoint-multiselect: 0.625rem;
  --space-y-submit: 1.25rem;
  container-type: inline-size;
  margin-left: var(--space-x-small-breakpoint-container);
  margin-right: var(--space-x-small-breakpoint-container);
}

fieldset legend {
  color: var(--color-legend);
  display: block;
  font-size: var(--size-small-breakpoint-legend);
  font-weight: var(--weight-legend);
  margin-bottom: var(--space-y-block-small-breakpoint-legend);
}

.form-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .form-element {
    width: 100%;
  }
}

.form-element {
  margin-bottom: var(--space-y-block-small-breakpoint-form-element);
  position: relative;

  .form-element-label {
    display: flex;

    .form-element-label-addon {
      color: var(--color-form-element-label-addon);
      display: block;
      font-size: var(--size-small-breakpoint-form-element-label-addon);
      margin-bottom: var(--space-y-block-legend-label-addon);
      margin-left: auto;
    }

    & label {
      color: var(--color-form-element-label);
      display: block;
      font-size: var(--size-small-breakpoint-form-element-label);
      font-weight: var(--weight-form-element-label);
      margin-bottom: var(--space-y-block-small-breakpoint-form-element-label);
    }
  }

  .form-element-icon {
    align-items: center;
    border-bottom-left-radius: var(--border-radius-form);
    border-top-left-radius: var(--border-radius-form);
    display: flex;
    height: var(--height-form-element-icon);
    inset: var(--inset-form-element-icon);
    justify-content: center;
    position: absolute;
    width: var(--width-small-breakpoint-form-element-icon);

    svg {
      color: var(--color-form-element-icon);
      height: var(--size-small-breakpoint-form-element-icon);
      width: var(--size-small-breakpoint-form-element-icon);
    }

    + .input {
      padding-left: var(--space-x-small-breakpoint-form-element-icon);
    }
  }

  .form-element-input {
    position: relative;
  }

  .input {
    appearance: none;
    background: var(--background-input);
    border-color: var(--border-color-input);
    border-radius: var(--border-radius-input);
    border-style: solid;
    border-width: var(--border-width-input);
    color: var(--color-input);
    display: block;
    font-size: var(--size-small-breakpoint-input);
    margin-bottom: 0;
    padding: var(--space-y-small-breakpoint-input) var(--space-x-small-breakpoint-input);
    transition: all 0.3s;
    width: 100%;
    box-shadow: 0 1px 2px 0 #1018280d;

    &::placeholder {
      color: var(--color-input-placeholder);
      opacity: 1;
    }

    &:focus {
      border-color: var(--border-color-input-focus);
      box-shadow: 0 0 0 var(--size-form-outline) var(--color-form-outline);
      outline: 0;
    }

    &.input--error {
      border-color: var(--border-color-input-error);
      box-shadow: 0 0 0 var(--size-form-outline) var(--color-form-outline-error);
      color: var(--color-input-error);
    }
  }
}

.textarea {
  appearance: none;
  background: var(--background-textarea);
  background-clip: padding-box;
  border-color: var(--border-color-textarea);
  border-radius: var(--border-radius-textarea);
  border-style: solid;
  border-width: var(--border-width-textarea);
  color: var(--color-textarea);
  display: block;
  font-size: var(--size-small-breakpoint-input);
  margin-bottom: 0;
  min-height: var(--min-height-small-breakpoint-textarea);
  padding: var(--space-y-small-breakpoint-textarea) var(--space-x-small-breakpoint-textarea);
  transition: all 0.3s;
  width: 100%;
  box-shadow: 0 1px 2px 0 #1018280d;

  &::placeholder {
    color: var(--color-textarea-placeholder);
    opacity: 1;
  }

  &:focus {
    border-color: var(--border-color-textarea-focus);
    box-shadow: 0 0 0 var(--size-form-outline) var(--color-form-outline);
    outline: 0;
  }

  &.textarea--error {
    border-color: var(--border-color-textarea-error);
    box-shadow: 0 0 0 var(--size-form-outline) var(--color-form-outline-error);
  }
}

.checkbox {
  color: var(--color-checkbox);
  position: relative;
  transition: all 0.3s;

  & label {
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    display: flex;
  }

  .checkbox-label {
    color: var(--color-checkbox-label);
    display: flex;
    font-size: var(--size-small-breakpoint-checkbox-label);
    padding: var(--space-y-small-breakpoint-checkbox) var(--space-x-small-breakpoint-checkbox);
  }

  .checkbox-icon {
    height: var(--size-checkbox-icon);
    position: relative;
    width: var(--size-checkbox-icon);

    &::after {
      background-color: var(--color-checkbox-icon);
      content: '';
      display: block;
      height: var(--size-small-breakpoint-checkbox-icon);
      -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='%23D2D6DB' d='M14 18H4c-2.21 0-4-1.79-4-4V4c0-2.21 1.79-4 4-4h10c2.21 0 4 1.79 4 4v10c0 2.21-1.79 4-4 4M4 1C2.35 1 1 2.35 1 4v10c0 1.65 1.35 3 3 3h10c1.65 0 3-1.35 3-3V4c0-1.65-1.35-3-3-3z'/%3E%3C/svg%3E");
      mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='%23D2D6DB' d='M14 18H4c-2.21 0-4-1.79-4-4V4c0-2.21 1.79-4 4-4h10c2.21 0 4 1.79 4 4v10c0 2.21-1.79 4-4 4M4 1C2.35 1 1 2.35 1 4v10c0 1.65 1.35 3 3 3h10c1.65 0 3-1.35 3-3V4c0-1.65-1.35-3-3-3z'/%3E%3C/svg%3E");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      min-width: var(--size-small-breakpoint-checkbox-icon);
      width: var(--size-small-breakpoint-checkbox-icon);
    }
  }

  & input[type='checkbox'] {
    cursor: pointer;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 5px;

    &:checked + .checkbox-icon::after {
      background-color: var(--color-checkbox-icon-checked);
      content: '';
      display: block;
      height: var(--size-small-breakpoint-checkbox-icon);
      -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='%232E90FA' d='M14 0H4C1.79 0 0 1.79 0 4v10c0 2.21 1.79 4 4 4h10c2.21 0 4-1.79 4-4V4c0-2.21-1.79-4-4-4M2.96 8.29a.996.996 0 0 1 1.41 0L7 10.92l6.63-6.63a.996.996 0 1 1 1.41 1.41l-7.33 7.33c-.2.2-.45.29-.71.29s-.51-.1-.71-.29L2.96 9.7a.996.996 0 0 1 0-1.41'/%3E%3C/svg%3E");
      mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='%232E90FA' d='M14 0H4C1.79 0 0 1.79 0 4v10c0 2.21 1.79 4 4 4h10c2.21 0 4-1.79 4-4V4c0-2.21-1.79-4-4-4M2.96 8.29a.996.996 0 0 1 1.41 0L7 10.92l6.63-6.63a.996.996 0 1 1 1.41 1.41l-7.33 7.33c-.2.2-.45.29-.71.29s-.51-.1-.71-.29L2.96 9.7a.996.996 0 0 1 0-1.41'/%3E%3C/svg%3E");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      min-width: var(--size-small-breakpoint-checkbox-icon);
      width: var(--size-small-breakpoint-checkbox-icon);
    }
  }

  &:not(:last-child) {
    margin-bottom: var(--space-y-block-small-breakpoint-checkbox);
  }
}

.radio {
  color: var(--color-radio);
  position: relative;
  transition: all 0.3s;

  & label {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 10px;
  }

  .radio-label {
    color: var(--color-radio-label);
    font-size: var(--size-small-breakpoint-radio-label);
    padding: var(--space-y-small-breakpoint-radio) var(--space-x-small-breakpoint-radio);
  }

  .radio-icon {
    position: relative;

    &::after {
      background-color: var(--color-radio-icon);
      content: '';
      display: block;
      height: var(--size-small-breakpoint-radio-icon);
      -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 18'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%236C737F'/%3E%3C/svg%3E");
      mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 18'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%236C737F'/%3E%3C/svg%3E");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      min-width: var(--size-small-breakpoint-radio-icon);
      width: var(--size-small-breakpoint-radio-icon);
    }
  }

  & input[type='radio'] {
    cursor: pointer;
    left: 0.3125rem;
    opacity: 0;
    position: absolute;
    top: 0.3125rem;

    &:checked + .radio-icon::after {
      background-color: var(--color-radio-icon-active);
      content: '';
      display: block;
      height: var(--size-small-breakpoint-radio-icon);
      -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cg fill='%234b85c5'%3E%3Cpath d='M9 18c-4.96 0-9-4.04-9-9s4.04-9 9-9 9 4.04 9 9-4.04 9-9 9M9 1C4.59 1 1 4.59 1 9s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8'/%3E%3Ccircle cx='9' cy='9' r='4'/%3E%3C/g%3E%3C/svg%3E");
      mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cg fill='%234b85c5'%3E%3Cpath d='M9 18c-4.96 0-9-4.04-9-9s4.04-9 9-9 9 4.04 9 9-4.04 9-9 9M9 1C4.59 1 1 4.59 1 9s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8'/%3E%3Ccircle cx='9' cy='9' r='4'/%3E%3C/g%3E%3C/svg%3E");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      min-width: var(--size-small-breakpoint-radio-icon);
      width: var(--size-small-breakpoint-radio-icon);
    }
  }

  &:not(:last-child) {
    margin-bottom: var(--space-y-block-small-breakpoint-radio);
  }
}

.select-native {
  position: relative;
  .select-icon {
    &::after {
      background-color: var(--color-select-trigger-icon);
      content: '';
      display: block;
      height: var(--size-select-trigger-icon);
      -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 -4.5 16.5 16.5'%3E%3Cpath fill='%236c737f' d='M7 8c-.26 0-.51-.1-.71-.29l-6-6C-.1 1.32-.1.68.29.29s1.03-.39 1.42 0L7 5.59l5.29-5.3A.996.996 0 1 1 13.7 1.7L7.71 7.71c-.2.2-.45.29-.71.29'/%3E%3C/svg%3E");
      mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 -4.5 16.5 16.5'%3E%3Cpath fill='%236c737f' d='M7 8c-.26 0-.51-.1-.71-.29l-6-6C-.1 1.32-.1.68.29.29s1.03-.39 1.42 0L7 5.59l5.29-5.3A.996.996 0 1 1 13.7 1.7L7.71 7.71c-.2.2-.45.29-.71.29'/%3E%3C/svg%3E");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      min-width: var(--size-select-trigger-icon);
      position: absolute;
      right: 20px;
      top: 17px;
      transform: rotate(0);
      transition: all 0.1s;
      width: var(--size-select-trigger-icon);
    }
  }
}
.select {
  appearance: none;
  background: var(--background-input);
  border-color: var(--border-color-input);
  border-radius: var(--border-radius-input);
  border-style: solid;
  border-width: var(--border-width-input);
  color: var(--color-input);
  display: block;
  font-size: var(--size-small-breakpoint-input);
  margin-bottom: 0;
  padding: var(--space-y-small-breakpoint-input) var(--space-x-small-breakpoint-input);
  transition: all 0.3s;
  width: 100%;
  box-shadow: 0 1px 2px 0 #1018280d;

  &::placeholder {
    color: var(--color-input-placeholder);
    opacity: 1;
  }

  &:focus {
    border-color: var(--border-color-input-focus);
    box-shadow: 0 0 0 var(--size-form-outline) var(--color-form-outline);
    outline: 0;
  }

  & select {
    display: none;
  }

  .select-trigger {
    align-items: center;
    background: var(--background-select-trigger);
    border-color: var(--border-color-select-trigger);
    border-radius: var(--border-radius-select-trigger);
    border-style: solid;
    border-width: var(--border-width-select-trigger);
    color: var(--color-select-trigger);
    cursor: pointer;
    display: flex;
    font-size: var(--size-small-breakpoint-select-trigger);
    padding: var(--space-y-small-breakpoint-select) var(--space-x-small-breakpoint-select);
    position: relative;
    transition: all var(--transition-duration) ease-in-out;
    width: 100%;
    box-shadow: 0 1px 2px 0 #1018280d;

    &.visible {
      border-color: var(--border-color-select-trigger-visible);
      border-radius: var(--border-radius-select-trigger-visible);
      box-shadow: 0 0 0 var(--size-form-outline) var(--color-form-outline);

      &::after {
        transform: rotate(180deg);
      }
    }

    &.selected {
      color: var(--color-select-trigger-selected);
    }

    &::after {
      background-color: var(--color-select-trigger-icon);
      content: '';
      display: block;
      height: var(--size-select-trigger-icon);
      -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 -4.5 16.5 16.5'%3E%3Cpath fill='%236c737f' d='M7 8c-.26 0-.51-.1-.71-.29l-6-6C-.1 1.32-.1.68.29.29s1.03-.39 1.42 0L7 5.59l5.29-5.3A.996.996 0 1 1 13.7 1.7L7.71 7.71c-.2.2-.45.29-.71.29'/%3E%3C/svg%3E");
      mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 -4.5 16.5 16.5'%3E%3Cpath fill='%236c737f' d='M7 8c-.26 0-.51-.1-.71-.29l-6-6C-.1 1.32-.1.68.29.29s1.03-.39 1.42 0L7 5.59l5.29-5.3A.996.996 0 1 1 13.7 1.7L7.71 7.71c-.2.2-.45.29-.71.29'/%3E%3C/svg%3E");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100%;
      mask-size: 100%;
      min-width: var(--size-select-trigger-icon);
      position: absolute;
      right: 20px;
      top: auto;
      transform: rotate(0);
      transition: all 0.1s;
      width: var(--size-select-trigger-icon);
    }
  }

  .select-options {
    background: var(--background-select-options);
    border-color: var(--border-color-select-options);
    border-radius: var(--border-radius-select-options);
    border-style: solid;
    border-width: var(--border-width-select-options);
    box-shadow: none;
    display: none;
    left: 0;
    padding: var(--space-y-small-breakpoint-select-options)
      var(--space-x-small-breakpoint-select-options);
    position: absolute;
    top: auto;
    transition: all var(--transition-duration) ease-in-out;
    width: 100%;
    z-index: 1;

    &.visible {
      display: block;
    }

    .select-option {
      color: var(--color-select-option);
      cursor: pointer;
      font-weight: 400;
      padding: var(--space-y-small-breakpoint-select-option)
        var(--space-x-small-breakpoint-select-option);
      position: relative;
      transition: all var(--transition-duration) ease-in-out;

      &:not(:last-of-type)::after {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
      }

      &:hover {
        background: var(--background-select-option-hover);
      }
    }
  }
}

.form-element-message {
  align-items: center;
  display: flex;
  font-size: 0;
  gap: 5px;
  margin-top: 0;
  transition: margin var(--transition-duration);
  visibility: hidden;

  .form-element-message-icon {
    height: 0;
  }

  &.visible {
    font-size: var(--size-message);
    margin-top: var(--space-y-block-bottom-message);
    visibility: visible;
  }

  &.form-element-message--error {
    color: var(--color-message-error);

    .form-element-message-icon {
      height: auto;

      & svg {
        color: var(--color-message-error);
        height: var(--size-medium-breakpoint-message-error-icon);
        width: var(--size-medium-breakpoint-message-error-icon);
      }
    }
  }
}

.form-element-error .form-element-items {
  border-color: var(--border-color-message-error-items);
  border-radius: var(--border-radius-message-error-items);
  border-style: solid;
  border-width: var(--border-width-message-error-items);
  box-shadow: 0 0 0 var(--size-form-outline) var(--color-form-outline-error);
  padding: var(--space-y-small-breakpoint-message-items)
    var(--space-x-small-breakpoint-message-items);
}

.datepicker {
  position: relative;

  .datepicker-calendar {
    background: var(--background-datepicker);
    border-color: var(--border-color-datepicker);
    border-radius: var(--border-radius-datepicker);
    border-style: solid;
    border-width: var(--border-width-datepicker);
    display: none;
    left: 0;
    position: absolute;
    top: calc(100% + 10px);
    width: var(--width-small-breakpoint-datepicker);
    z-index: 3;
    box-shadow: 0 1px 2px 0 #1018280d;

    .datepicker-calendar-navigation {
      display: flex;
      padding: 1.25rem;

      .datepicker-month {
        align-items: center;
        color: var(--color-datepicker-month);
        display: flex;
        font-weight: var(--weight-form-element-label);
        gap: 10px;
        margin-right: auto;
      }

      .datepicker-button--prev {
        font-size: 0;
        margin-right: 0.5rem;

        &::after {
          background-color: var(--color-datepicker-prev-next);
          content: '';
          display: block;
          height: var(--size-datepicker-prev-next);
          -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -0.1 12 12'%3E%3Cpath fill='%236c7380' d='M5.75 11.5c-.19 0-.38-.07-.53-.22l-5-5a.75.75 0 0 1 0-1.06l5-5c.29-.29.77-.29 1.06 0s.29.77 0 1.06L1.81 5.75l4.47 4.47c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22'/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -0.1 12 12'%3E%3Cpath fill='%236c7380' d='M5.75 11.5c-.19 0-.38-.07-.53-.22l-5-5a.75.75 0 0 1 0-1.06l5-5c.29-.29.77-.29 1.06 0s.29.77 0 1.06L1.81 5.75l4.47 4.47c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22'/%3E%3C/svg%3E");
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100%;
          mask-size: 100%;
          min-width: var(--size-datepicker-prev-next);
          width: var(--size-datepicker-prev-next);
        }
      }

      .datepicker-button--next {
        font-size: 0;
        margin-left: 0.5rem;

        &::after {
          background-color: var(--color-datepicker-prev-next);
          content: '';
          display: block;
          height: var(--size-datepicker-prev-next);
          -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -0.2 12 12'%3E%3Cpath fill='%236c7380' d='M.75 11.5c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l4.47-4.47L.22 1.28C-.07.99-.07.51.22.22s.77-.29 1.06 0l5 5c.29.29.29.77 0 1.06l-5 5c-.15.15-.34.22-.53.22'/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -0.2 12 12'%3E%3Cpath fill='%236c7380' d='M.75 11.5c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l4.47-4.47L.22 1.28C-.07.99-.07.51.22.22s.77-.29 1.06 0l5 5c.29.29.29.77 0 1.06l-5 5c-.15.15-.34.22-.53.22'/%3E%3C/svg%3E");
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100%;
          mask-size: 100%;
          min-width: var(--size-datepicker-prev-next);
          width: var(--size-datepicker-prev-next);
        }
      }

      .datepicker-button {
        align-items: center;
        background: none;
        background: var(--background-hover-datepicker-prev-next);
        border: 0;
        border: 1px solid var(--border-color-datepicker-prev-next);
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        height: 32px;
        justify-content: center;
        width: 32px;

        &:active,
        &:focus,
        &:hover {
          background: none;
        }
      }
    }

    .datepicker-calendar-header {
      border-bottom: var(--border-bottom-datepicker-calendar-header);
      margin-top: var(--space-y-block-datepicker-calendar-header);
      padding-bottom: var(--space-y-datepicker-calendar-header);

      .datepicker-calendar-header-days {
        color: var(--color-datepicker-calendar-header-days);
        display: flex;
        font-size: var(--size-datepicker-calendar-header-days);
        justify-content: space-around;
        weight: var(--weight-datepicker-calendar-header-days);
      }
    }

    .datepicker-calendar-day {
      align-items: center;
      background: none;
      color: var(--color-datepicker-calendar-day);
      cursor: pointer;
      display: flex;
      font-size: var(--size-datepicker-calendar-day);
      font-weight: var(--weight-datepicker-calendar-day);
      height: auto;
      justify-content: center;
      padding: var(--space-y-datepicker-calendar-day);
      position: relative;
      text-align: center;
      transition: all ease-in-out;
      width: 14.28%;

      & span {
        position: relative;
        z-index: 2;
      }

      &::before {
        background: var(--background-calendar);
        border-radius: 32px;
        content: '';
        display: block;
        height: 32px;
        left: auto;
        position: absolute;
        right: auto;
        transition: all ease-in-out;
        width: 32px;
        z-index: 1;
      }

      &:hover {
        & span {
          color: var(--color-datepicker-calendar-day-hover);
        }

        &::before {
          background: var(--background-datepicker-calendar-day-hover);
        }
      }

      &.visible {
        color: var(--color-datepicker-calendar-day-active);

        &::before {
          background: var(--background-datepicker-calendar-day-active);
        }
      }

      &:not(.datepicker-calendar-day-29, .datepicker-calendar-day-30, .datepicker-calendar-day-31, .datepicker-calendar-day-28:last-child) {
        border-bottom: var(--border-datepicker-calendar-day);
      }
    }

    .datepicker-calendar-days {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &.visible {
      display: block;
    }
  }
}

.select-multiselect-count {
  background: var(--background-multiselect);
  border-radius: var(--border-radius-multiselect);
  color: var(--color-multiselect);
  display: none;
  font-size: var(--size-multiselect);
  margin-left: var(--space-x-block-multiselect);
  padding: var(--space-y-multiselect-count) var(--space-x-multiselect-count);

  &.visible {
    display: inline-block;
  }
}

.select-multiselect {
  padding: var(--space-y-small-breakpoint-multiselect) var(--space-x-small-breakpoint-multiselect);
}

.form-submit {
  margin-top: var(--space-y-submit);
}

@media (width >= 768px) {
  .form {
    margin-left: var(--space-x-medium-breakpoint-container);
    margin-right: var(--space-x-medium-breakpoint-container);
  }

  fieldset legend {
    font-size: var(--size-medium-breakpoint-legend);
    margin-bottom: var(--space-y-block-medium-breakpoint-legend);
  }

  .form-grid {
    column-gap: var(--column-gap-form-grid);

    .form-element {
      flex: 1;
    }
  }

  .form-element {
    margin-bottom: var(--space-y-block-medium-breakpoint-form-element);

    .form-element-label {
      .form-element-label-addon {
        font-size: var(--size-medium-breakpoint-form-element-label-addon);
      }

      & label {
        font-size: var(--size-medium-breakpoint-form-element-label);
        margin-bottom: var(--space-y-block-medium-breakpoint-form-element-label);
      }
    }

    .form-element-icon {
      width: var(--width-medium-breakpoint-form-element-icon);

      & svg {
        height: var(--size-medium-breakpoint-form-element-icon);
        width: var(--size-medium-breakpoint-form-element-icon);
      }

      + .input {
        padding-left: var(--space-x-medium-breakpoint-form-element-icon);
      }
    }

    .input {
      font-size: var(--size-medium-breakpoint-input);
      padding: var(--space-y-medium-breakpoint-input) var(--space-x-medium-breakpoint-input);
    }
  }

  .textarea {
    font-size: var(--size-medium-breakpoint-input);
    min-height: var(--min-height-medium-breakpoint-textarea);
    padding: var(--space-y-medium-breakpoint-textarea) var(--space-x-medium-breakpoint-textarea);
  }

  .checkbox {
    .checkbox-label {
      font-size: var(--size-medium-breakpoint-checkbox-label);
      padding: var(--space-y-medium-breakpoint-checkbox) var(--space-x-medium-breakpoint-checkbox);
    }

    .checkbox-icon::after,
    input[type='checkbox']:checked + .checkbox-icon::after {
      height: var(--size-medium-breakpoint-checkbox-icon);
      min-width: var(--size-medium-breakpoint-checkbox-icon);
      width: var(--size-medium-breakpoint-checkbox-icon);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-y-block-medium-breakpoint-checkbox);
    }
  }

  .radio {
    .radio-label {
      font-size: var(--size-medium-breakpoint-radio-label);
      padding: var(--space-y-medium-breakpoint-radio) var(--space-x-medium-breakpoint-radio);
    }

    .radio-icon::after {
      height: var(--size-medium-breakpoint-radio-icon);
      min-width: var(--size-medium-breakpoint-radio-icon);
      width: var(--size-medium-breakpoint-radio-icon);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-y-block-medium-breakpoint-radio);
    }
  }

  .select {
    .select-trigger {
      font-size: var(--size-medium-breakpoint-select-trigger);
      padding: var(--space-y-medium-breakpoint-select) var(--space-x-medium-breakpoint-select);
    }

    .select-options {
      padding: var(--space-y-medium-breakpoint-select-options)
        var(--space-x-medium-breakpoint-select-options);

      .select-option {
        padding: var(--space-y-medium-breakpoint-select-option)
          var(--space-x-medium-breakpoint-select-option);
      }
    }
  }

  .form-element-message.form-element-message--error .form-element-message-icon svg {
    min-width: var(--size-medium-breakpoint-message-error-icon);
  }

  .form-element-error .form-element-items {
    padding: var(--space-y-medium-breakpoint-message-items)
      var(--space-x-medium-breakpoint-message-items);
  }

  .datepicker .datepicker-calendar {
    width: var(--width-medium-breakpoint-datepicker);
  }

  .select-multiselect {
    padding: var(--space-y-medium-breakpoint-multiselect)
      var(--space-x-medium-breakpoint-multiselect);
  }
}

@media (width >= 992px) {
  .form {
    margin-left: var(--space-x-large-breakpoint-container);
    margin-right: var(--space-x-large-breakpoint-container);
    max-width: var(--max-width-container-small);
  }

  fieldset legend {
    font-size: var(--size-large-breakpoint-legend);
    margin-bottom: var(--space-y-block-large-breakpoint-legend);
  }

  .form-element {
    margin-bottom: var(--space-y-block-large-breakpoint-form-element);

    .form-element-label {
      .form-element-label-addon {
        font-size: var(--size-large-breakpoint-form-element-label-addon);
      }

      & label {
        font-size: var(--size-large-breakpoint-form-element-label);
        margin-bottom: var(--space-y-block-large-breakpoint-form-element-label);
      }
    }

    .form-element-icon {
      width: var(--width-large-breakpoint-form-element-icon);

      & svg {
        height: var(--size-large-breakpoint-form-element-icon);
        width: var(--size-large-breakpoint-form-element-icon);
      }

      + .input {
        padding-left: var(--space-x-large-breakpoint-form-element-icon);
      }
    }

    .input {
      font-size: var(--size-large-breakpoint-input);
      padding: var(--space-y-large-breakpoint-input) var(--space-x-large-breakpoint-input);
    }
  }

  .textarea {
    font-size: var(--size-large-breakpoint-input);
    min-height: var(--min-height-large-breakpoint-textarea);
    padding: var(--space-y-large-breakpoint-textarea) var(--space-x-large-breakpoint-textarea);
  }

  .checkbox {
    .checkbox-label {
      font-size: var(--size-large-breakpoint-checkbox-label);
      padding: var(--space-y-large-breakpoint-checkbox) var(--space-x-large-breakpoint-checkbox);
    }

    .checkbox-icon::after,
    input[type='checkbox']:checked + .checkbox-icon::after {
      height: var(--size-large-breakpoint-checkbox-icon);
      min-width: var(--size-large-breakpoint-checkbox-icon);
      width: var(--size-large-breakpoint-checkbox-icon);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-y-block-large-breakpoint-checkbox);
    }
  }

  .radio {
    .radio-label {
      font-size: var(--size-large-breakpoint-radio-label);
      padding: var(--space-y-large-breakpoint-radio) var(--space-x-large-breakpoint-radio);
    }

    .radio-icon::after {
      height: var(--size-large-breakpoint-radio-icon);
      min-width: var(--size-large-breakpoint-radio-icon);
      width: var(--size-large-breakpoint-radio-icon);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-y-block-large-breakpoint-radio);
    }
  }

  .select {
    .select-trigger {
      font-size: var(--size-large-breakpoint-select-trigger);
      padding: var(--space-y-large-breakpoint-select) var(--space-x-large-breakpoint-select);
    }

    .select-options {
      padding: var(--space-y-large-breakpoint-select-options)
        var(--space-x-large-breakpoint-select-options);

      .select-option {
        padding: var(--space-y-large-breakpoint-select-option)
          var(--space-x-large-breakpoint-select-option);
      }
    }
  }

  .form-element-message.form-element-message--error .form-element-message-icon svg {
    height: var(--size-large-breakpoint-message-error-icon);
    width: var(--size-large-breakpoint-message-error-icon);
  }

  .form-element-error .form-element-items {
    padding: var(--space-y-large-breakpoint-message-items)
      var(--space-x-large-breakpoint-message-items);
  }

  .datepicker .datepicker-calendar {
    width: var(--width-large-breakpoint-datepicker);
  }

  .select-multiselect {
    padding: var(--space-y-large-breakpoint-multiselect) var(--space-x-large-breakpoint-multiselect);
  }
}

@media (width >= 1200px) {
  .form {
    max-width: var(--max-width-container-extralarge);
  }
}

@media (width >= 1300px) {
  .form {
    margin-left: auto;
    margin-right: auto;
  }
}
