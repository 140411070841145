.card {
  container-type: inline-size;
  margin-top: 2rem;
}

.card .card-inner {
  margin-left: var(--space-small-breakpoint-container);
  margin-right: var(--space-small-breakpoint-container);
  position: relative;
}

@container (width >=768px) {
  .card .card-inner {
    margin: 0 var(--space-medium-breakpoint-container) 0 var(--space-medium-breakpoint-container);
  }
}

@container (width >=1200px) {
  .card .card-inner {
    margin-left: var(--space-large-breakpoint-container);
    margin-right: var(--space-large-breakpoint-container);
  }
}

@container (width >=1400px) {
  .card .card-inner {
    max-width: var(--max-width-container-extralarge);
  }
}

.card .card-inner .card-item {
  background: var(--background-card);
  border-radius: var(--border-radius-card);
  padding: var(--space-y-small-breakpoint-card) var(--space-x-small-breakpoint-card);
  position: relative;
}

@media (min-width: 768px) {
  .card .card-inner .card-item {
    padding: var(--space-y-medium-breakpoint-card) var(--space-x-medium-breakpoint-card);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item {
    padding: var(--space-y-large-breakpoint-card) var(--space-x-large-breakpoint-card);
  }
}

.card .card-inner .card-item .card-icon {
  background: var(--background-card-icon);
  border-radius: var(--border-radius-card-icon);
  display: inline-flex;
  margin-bottom: var(--space-block-y-small-breakpoint-card-icon);
  padding: var(--space-y-small-breakpoint-card-icon) var(--space-x-small-breakpoint-card-icon);
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-icon {
    margin-bottom: var(--space-block-y-medium-breakpoint-card-icon);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-icon {
    margin-bottom: var(--space-block-y-large-breakpoint-card-icon);
  }
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-icon {
    padding: var(--space-y-medium-breakpoint-card-icon) var(--space-x-medium-breakpoint-card-icon);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-icon {
    padding: var(--space-y-large-breakpoint-card-icon) var(--space-x-large-breakpoint-card-icon);
  }
}

.card .card-inner .card-item .card-icon i {
  height: var(--size-card-icon);
  width: var(--size-card-icon);
}

.card .card-inner .card-item .card-icon i::after {
  background: var(--color-card-icon);
  height: var(--size-card-icon);
  min-width: var(--size-card-icon);
  width: var(--size-card-icon);
}

.card .card-inner .card-item .card-text .card-eyebrow {
  background: var(--background-card-eyebrow);
  border-color: var(--border-color-card-eyebrow);
  border-radius: var(--border-radius-card-eyebrow);
  border-style: solid;
  border-width: var(--border-width-card-eyebrow);
  color: var(--color-card-eyebrow);
  display: inline-flex;
  font-size: var(--size-small-breakpoint-card-eyebrow);
  font-weight: var(--weight-card-eyebrow);
  line-height: var(--line-height-small-breakpoint-card-eyebrow);
  margin-bottom: var(--space-block-y-small-breakpoint-card-eyebrow);
  padding: var(--space-y-small-breakpoint-card-eyebrow) var(--space-x-small-breakpoint-card-eyebrow);
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    margin-bottom: var(--space-block-y-medium-breakpoint-card-eyebrow);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    margin-bottom: var(--space-block-y-large-breakpoint-card-eyebrow);
  }
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    line-height: var(--line-height-medium-breakpoint-card-eyebrow);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    line-height: var(--line-height-large-breakpoint-card-eyebrow);
  }
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    font-size: var(--size-medium-breakpoint-card-eyebrow);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    font-size: var(--size-large-breakpoint-card-eyebrow);
  }
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    padding: var(--space-y-medium-breakpoint-card-eyebrow)
      var(--space-x-medium-breakpoint-card-eyebrow);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-eyebrow {
    padding: var(--space-y-large-breakpoint-card-eyebrow)
      var(--space-x-large-breakpoint-card-eyebrow);
  }
}

.card-headline {
  margin-bottom: 25px;
}

.card .card-inner .card-item .card-text .card-headline h1,
.card .card-inner .card-item .card-text .card-headline h2,
.card .card-inner .card-item .card-text .card-headline h3,
.card .card-inner .card-item .card-text .card-headline h4,
.card .card-inner .card-item .card-text .card-headline h5,
.card .card-inner .card-item .card-text .card-headline h6,
.card .card-inner .card-item .card-text .card-headline span {
  color: var(--color-card-headline);
  display: block;
  font-family: var(--font-text);
  font-size: var(--size-small-breakpoint-card-headline);
  font-weight: var(--weight-card-headline);
  line-height: var(--line-height-small-breakpoint-card-headline);
  margin-bottom: var(--space-block-y-small-breakpoint-card-headline);
  transition: all 0.4s ease;
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-headline h1,
  .card .card-inner .card-item .card-text .card-headline h2,
  .card .card-inner .card-item .card-text .card-headline h3,
  .card .card-inner .card-item .card-text .card-headline h4,
  .card .card-inner .card-item .card-text .card-headline h5,
  .card .card-inner .card-item .card-text .card-headline h6,
  .card .card-inner .card-item .card-text .card-headline span {
    line-height: var(--line-height-medium-breakpoint-card-headline);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-headline h1,
  .card .card-inner .card-item .card-text .card-headline h2,
  .card .card-inner .card-item .card-text .card-headline h3,
  .card .card-inner .card-item .card-text .card-headline h4,
  .card .card-inner .card-item .card-text .card-headline h5,
  .card .card-inner .card-item .card-text .card-headline h6,
  .card .card-inner .card-item .card-text .card-headline span {
    line-height: var(--line-height-large-breakpoint-card-headline);
  }
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-headline h1,
  .card .card-inner .card-item .card-text .card-headline h2,
  .card .card-inner .card-item .card-text .card-headline h3,
  .card .card-inner .card-item .card-text .card-headline h4,
  .card .card-inner .card-item .card-text .card-headline h5,
  .card .card-inner .card-item .card-text .card-headline h6,
  .card .card-inner .card-item .card-text .card-headline span {
    font-size: var(--size-medium-breakpoint-card-headline);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-headline h1,
  .card .card-inner .card-item .card-text .card-headline h2,
  .card .card-inner .card-item .card-text .card-headline h3,
  .card .card-inner .card-item .card-text .card-headline h4,
  .card .card-inner .card-item .card-text .card-headline h5,
  .card .card-inner .card-item .card-text .card-headline h6,
  .card .card-inner .card-item .card-text .card-headline span {
    font-size: var(--size-large-breakpoint-card-headline);
  }
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-headline h1,
  .card .card-inner .card-item .card-text .card-headline h2,
  .card .card-inner .card-item .card-text .card-headline h3,
  .card .card-inner .card-item .card-text .card-headline h4,
  .card .card-inner .card-item .card-text .card-headline h5,
  .card .card-inner .card-item .card-text .card-headline h6,
  .card .card-inner .card-item .card-text .card-headline span {
    margin-bottom: var(--space-block-y-medium-breakpoint-card-headline);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-headline h1,
  .card .card-inner .card-item .card-text .card-headline h2,
  .card .card-inner .card-item .card-text .card-headline h3,
  .card .card-inner .card-item .card-text .card-headline h4,
  .card .card-inner .card-item .card-text .card-headline h5,
  .card .card-inner .card-item .card-text .card-headline h6,
  .card .card-inner .card-item .card-text .card-headline span {
    margin-bottom: var(--space-block-y-large-breakpoint-card-headline);
  }
}

.card .card-inner .card-item .card-text .card-button .card-button-primary {
  font-size: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.card .card-inner .card-item .card-text .card-text {
  color: var(--color-card-text);
  font-size: var(--size-small-breakpoint-card-text);
  font-weight: var(--weight-card-text);
  line-height: var(--line-height-small-breakpoint-card-text);
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-text {
    line-height: var(--line-height-medium-breakpoint-card-text);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-text {
    line-height: var(--line-height-large-breakpoint-card-text);
  }
}

@media (min-width: 768px) {
  .card .card-inner .card-item .card-text .card-text {
    font-size: var(--size-medium-breakpoint-card-text);
  }
}

@media (min-width: 1200px) {
  .card .card-inner .card-item .card-text .card-text {
    font-size: var(--size-large-breakpoint-card-text);
  }
}

.card-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}

@media (min-width: 1200px) {
  .card-grid {
    flex-direction: row;
    gap: var(--gap-card-grid);
  }
}

@media (min-width: 1200px) {
  .card-grid .card-item {
    flex: 1 0 calc(33.3333333333% - var(--gap-card-grid));
    max-width: calc(33.3333333333% - var(--gap-card-grid) / 3 * 2);
    width: auto;
  }
}

.card-grid .card-item .card-text {
  padding-right: 0;
}

.card-grid .card-item:not(:last-child) {
  margin-bottom: var(--space-block-y-small-breakpoint-card-grid-item);
}

@media (min-width: 768px) {
  .card-grid .card-item:not(:last-child) {
    margin-bottom: var(--space-block-y-medium-breakpoint-card-grid-item);
  }
}

@media (min-width: 1200px) {
  .card-grid .card-item:not(:last-child) {
    margin-bottom: var(--space-block-y-large-breakpoint-card-grid-item);
  }
}

@media (min-width: 1200px) {
  .card-grid.card--grid-2 .card-item {
    flex: 1 0 calc(50% - var(--gap-card-grid));
    max-width: calc(50% - var(--gap-card-grid) / 2 * 1);
    width: auto;
  }
}

@media (min-width: 1200px) {
  .card-grid.card--grid-3 .card-item {
    flex: 1 0 calc(33.3333333333% - var(--gap-card-grid));
    max-width: calc(33.3333333333% - var(--gap-card-grid) / 3 * 2);
    width: auto;
  }
}

@media (min-width: 1200px) {
  .card-grid.card--grid-4 .card-item {
    flex: 1 0 calc(25% - var(--gap-card-grid));
    max-width: calc(25% - var(--gap-card-grid) / 4 * 3);
    width: auto;
  }
}
