.results {
  display: flex;
  gap: 60px;
  @media (max-width: 768px) {
    display: none;
  }
  .result {
    flex-basis: 50%;
    &:not(:empty) {
      font-size: 1.25rem;
      margin-block: 1.25rem;
      color: var(--neutral-900);
      font-weight: var(--weight-bold);
      padding: 20px 0;
      & h2 {
        background: #e0ffff;
        display: inline;
        padding: 5px;
      }
    }
  }
}
