.metric {
  display: flex;
  container-type: inline-size;
  background: url('https://www.auto-freydank.de/media/57/de/c8/1702897423/slider_hyundai-ioniq-5-n.webp');
  background-position: center;
  background-size: cover;
  min-height: 350px;
  max-width: var(--max-width-container-extralarge);
  margin: 0 20px;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  padding: 20px;
  @media (min-width: 768px) {
    margin: 0 auto;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.3);
  }
  .metric-title {
    color: #fff;
    z-index: 3;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 2.5rem;
    font-size: 1.25rem;
    font-weight: var(--weight-bold);
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    & span {
      background: #e0ffff;
      color: #000;
      padding: 0 0.3125rem;
      margin: 0 0.4375rem;
    }
  }
  .metric-inner {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    gap: 20px;
    align-items: center;
    transition: all 0.2s ease-in-out;

    .metric-item {
      backdrop-filter: blur(12px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 32px 24px;
      background: rgba(255, 255, 255, 0.3);
      flex: auto;
      transition: all 0.2s ease-in-out;
      .metrix-title {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }
      .metric-number {
        font-size: 40px;
        font-weight: 600;
        color: #fff;
      }
    }
  }
}

@container (width >=768px) {
  .metric .metric-inner {
    padding: 0;
    margin: 0 var(--space-medium-breakpoint-container) 0 var(--space-medium-breakpoint-container);
  }
}

@container (width >=1200px) {
  .metric .metric-inner {
    margin-left: var(--space-large-breakpoint-container);
    margin-right: var(--space-large-breakpoint-container);
    max-width: var(--max-width-container-medium);
  }
}

@container (width >=1400px) {
  .metric .metric-inner {
    max-width: var(--max-width-container-medium);
  }
}
